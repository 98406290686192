/******************************
 * 验证格式
******************************/
export const ValidateIdCard = (value: string): boolean => {
  if (!(/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value))) {
    return false;
  }
  return true;
}
export const ValidatePhone = (value: string): boolean => {
  if (!(/^1[3456789]\d{9}$/.test(value))) {
    return false;
  }
  return true;
}
