/******************************
 * 设置缓存
******************************/
interface StorageItem {
  key: string;
  value: string;
  expires?: number;
  startTime?: number;
}
import { Config } from '@/js/Config'
// 设置缓存数据
export const setStorage: api.storage['setStorage'] = (name, value, expires) => {
  let key = Config.STORAGE_HEAD + name
  let params = { key, value, expires: expires ? expires * 1000 : expires };
  if (expires) {
    // 记录何时将值存入缓存，毫秒级
    let data: StorageItem = Object.assign(params, { startTime: new Date().getTime() });
    localStorage.setItem(key, JSON.stringify(data));
  } else {
    localStorage.setItem(key, JSON.stringify(params));
  }
}
// 获取缓存数据
export const getStorage: api.storage['getStorage'] = (name) => {
  let key = Config.STORAGE_HEAD + name
  let item: StorageItem;
  let getItem: string = localStorage.getItem(key) || '';
  // 先将拿到的试着进行json转为对象的形式
  try {
    item = JSON.parse(getItem);
  } catch (error) {
    return null;
  }
  // 如果有startTime的值，说明设置了失效时间
  if (item && item.startTime && item.expires) {
    let date = new Date().getTime();
    // 如果大于就是过期了，如果小于或等于就还没过期
    if (date - item.startTime > item.expires) {
      localStorage.removeItem(name);
      return null;
    } else {
      return item.value;
    }
  } else {
    return item.value;
  }
}
// 删除缓存数据
export const removeStorage: api.storage['removeStorage'] = (name) => {
  const key = escape(Config.STORAGE_HEAD + name)
  window.localStorage.removeItem(key)
}
// 设置缓存数据
export const setSession: api.storage['setSession'] = (name, value, expires) => {
  let key = Config.STORAGE_HEAD + name
  let params = { key, value, expires: expires ? expires * 1000 : expires };
  if (expires) {
    // 记录何时将值存入缓存，毫秒级
    let data: StorageItem = Object.assign(params, { startTime: new Date().getTime() });
    sessionStorage.setItem(key, JSON.stringify(data));
  } else {
    sessionStorage.setItem(key, JSON.stringify(params));
  }
}
// 获取缓存数据
export const getSession: api.storage['getSession'] = (name) => {
  let key = Config.STORAGE_HEAD + name
  let item: StorageItem;
  let getItem: string = sessionStorage.getItem(key) || '';
  // 先将拿到的试着进行json转为对象的形式
  try {
    item = JSON.parse(getItem);
  } catch (error) {
    return null;
  }
  // 如果有startTime的值，说明设置了失效时间
  if (item && item.startTime && item.expires) {
    let date = new Date().getTime();
    // 如果大于就是过期了，如果小于或等于就还没过期
    if (date - item.startTime > item.expires) {
      sessionStorage.removeItem(name);
      return null;
    } else {
      return item.value;
    }
  } else {
    return item.value;
  }
}
// 删除缓存数据
export const removeSession: api.storage['removeSession'] = (name) => {
  const key = escape(Config.STORAGE_HEAD + name)
  window.sessionStorage.removeItem(key)
}
