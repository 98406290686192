
























import { Component, Vue, Watch } from "vue-property-decorator";
import { Config } from "@/js/Config";
import Event from "@/core/Event";
import md5 from "js-md5";
import { getConfig } from "@/js/api/wx";


@Component
export default class App extends Vue {
  public title = ""; // 标题
  public key = false; // 是否获取到key值，获取到后显示页面内容
  @Watch("$route")
  routeChange(to, from) {
    this.title = "";
    let title =   "蜜印";
    document.title = title; // 设置原生标题
    console.log(to, from)
    getConfig()
  }

  onClickLeft() {
    this.$api.goBack();
  }
  // 监听滚动高度
  scrollEvent(e) {
    let route = String(this.$route.name);
    this.$globalData.scroll_keep_alive[route] = e.srcElement.scrollTop;
  }
  created() {
    
  }
  beforeMount() {
    let _this = this;
    // 绑定更改标题的方法
    Event.$on("setTitle", (title: string) => {
      this.title = title;
      document.title = title; // 设置原生标题
    });
    // 登录逻辑
    if (!this.$api.getSession("token")) {
      // 没有缓存的token(或过期)，微信跳转微信登录，网页跳转登录页面
      if (this.$api.isWeixin()) {
        // 微信浏览器
        let key = this.$api.getSession("key_1");
        if (!key) {
          key = md5(Math.random() + "" + new Date().getTime());
          this.$api.setSession("key_1", String(key)); // 存储本地的key
        }
        const key2 = this.$api.getUrlParam("key"); // 微信登录成功后返回的key
        const openid = this.$api.getUrlParam("openid"); // 第一次登陆不生成key生成openid

        if (key2) {
          // 微信登录成功，存储token
          this.$api.request({
            url: "user/login/key-token",
            data: { key: md5(key + key2) },
            success(res) {
              _this.$api.removeSession("key_1");
              _this.$api.setSession("token", res.data.toke.access_token);
              _this.key = true;
            },
            fail() {
              // 失败就跳转重新登陆
              _this.$api.wxLogin(key);
            }
          });
        } else if (openid) {
          console.log(openid)
          this.$api.setSession("openid", String(openid)); // 存储本地的key
          this.$api.href("/login/basicInfo");
          this.key = true;
        } else {
          // 未登录，跳转登录
          _this.$api.wxLogin(key);
        }
      } else {
        // 网页跳转登录页面
        this.$api.href("/login");
        this.key = true;
      }
    } else {
      // 有token
      this.key = true;
    }
  }
  // 监听返回，允许页面缓存
  mounted() {
    console.log('1111111111')
    window.onpopstate = () => {
      // 将当前页面window.location.href 放入页面栈当中
      this.$globalData.refresh_keep_alive = false;
    };
  }
}
