/// <reference path="../components/MyForm.d.ts" />
/// <reference path="./api.d.ts" />

import * as route from './api/route'
import * as validate from './api/validate'
import * as date from './api/date'
import * as request from './api/request'
import * as wx from './api/wx'
import * as storage from './api/storage'
import * as other from './api/other'
import * as myForm from './api/myForm'

export default {
    ...validate,
    ...route,
    ...date,
    ...request,
    ...storage,
    ...other,
    ...myForm,
    wx: { ...wx },
}
