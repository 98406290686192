/******************************
 * wx-jssdk公用方法
******************************/
import { request } from './request';
import wx from 'wx-sdk-ts';
export const getConfig = function(): any {
  request({
    url: 'wechat/js/js-config',
    data: {
      url: window.location.href
    },
    success(res) {
      // let data = JSON.parse(res.data)
      let data = res.data
      // 初始化jssdk的config
      wx.config({
        debug: data.debug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: data.appId, // 必填，公众号的唯一标识
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature, // 必填，签名
        jsApiList: ['onMenuShareTimeline', 'onMenuShareAppMessage'] // 必填，需要使用的JS接口列表
      })
      wx.ready(function() {
        // 分享到朋友圈
        wx.onMenuShareTimeline({
            title: '来自蜜印的邀请', // 分享标题
            link: data.url, // 分享链接
            imgUrl: 'https://myxls.oss-cn-hangzhou.aliyuncs.com/images/my_logo.png', // 分享图标
            success: function () { 
                // 用户确认分享后执行的回调函数
            },
            cancel: function () { 
                // 用户取消分享后执行的回调函数
            }
        });
        // 分享到朋友
        wx.onMenuShareAppMessage({
          title: '来自蜜印的邀请', // 分享标题
          desc: '蜜印邀请您进来看看。。。。。', // 分享描述
          link: data.url, // 分享链接
          imgUrl: 'https://myxls.oss-cn-hangzhou.aliyuncs.com/images/my_logo.png', // 分享图标
          success: function () { 
              // 用户确认分享后执行的回调函数
          },
          cancel: function () { 
              // 用户取消分享后执行的回调函数
          }
      });
      });
      wx.error(function(err) {
        console.log(err)
      });
    },
  })
}

export const ValidateIdCard = (value: string): boolean => {
  if (!(/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value))) {
    return false;
  }
  return true;
}
export const ValidatePhone = (value: string): boolean => {
  if (!(/^1[3456789]\d{9}$/.test(value))) {
    return false;
  }
  return true;
}
