import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  { path: '/maintain', name: 'maintain', meta: { title: '蜜印', noback: true }, component: () => import('@/views/login/maintain.vue') },
  { path: '/login', name: 'LoginLogin', meta: { title: '登录', noback: true }, component: () => import('@/views/login/login.vue') },
  { path: '/login/forget', name: 'LoginForget', meta: { title: '修改密码' }, component: () => import('@/views/login/forget.vue') },
  { path: '/login/basicInfo', name: 'LoginBasicInfo', meta: { title: '完善个人信息' }, component: () => import('@/views/login/basicInfo.vue') },

  { path: '/', name: 'Index', meta: { noheader: true }, component: () => import('@/views/index/index.vue') },
  { path: '/scan_code', name: 'scanCcode', meta: { noheader: true }, component: () => import('@/views/index/scan_code.vue') },

  { path: '/home/shop', name: 'HomeShop', meta: { title: '店铺', noheader: true }, component: () => import('@/views/home/shop.vue') },
  { path: '/home/agent', name: 'HomeAgent', meta: { title: '代理', noheader: true }, component: () => import('@/views/home/agent.vue') },
  { path: '/home/debt', name: 'HomeDebt', meta: { title: '欠款详情', noheader: true}, component: () => import('@/views/home/debt.vue') },
  { path: '/home/pay', name: 'HomePay', meta: { title: '还款',}, component: () => import('@/views/home/pay.vue') },
  { path: '/home/cargo', name: 'HomeCargo', meta: { title: '出货详情',}, component: () => import('@/views/home/cargo.vue') },
  { path: '/home/list', name: 'HomeList', meta: { title: '出货记录',}, component: () => import('@/views/home/list.vue') },

  { path: '/activation', name: 'Activation', meta: { title: '激活名额' }, component: () => import('@/views/activation/index.vue') },
  { path: '/activation/add', name: 'ActivationAdd', meta: { title: '添加激活名额' }, component: () => import('@/views/activation/add.vue') },
  { path: '/activation/list', name: 'ActivationList', meta: { title: '激活名额' }, component: () => import('@/views/activation/list.vue') },

  { path: '/agent/county', name: 'AgentCounty', meta: { title: '区县代理' }, component: () => import('@/views/agent/county.vue') },
  { path: '/agent/shop', name: 'AgentShop', meta: { title: '区域门店' }, component: () => import('@/views/agent/shop.vue') },

  { path: '/user/bank', name: 'UserBank', meta: { title: '银行卡绑定' }, component: () => import('@/views/user/bank.vue') },
  { path: '/user/bank_info', name: 'UserBankInfo', meta: { title: '银行卡信息' }, component: () => import('@/views/user/bank_info.vue') },
  { path: '/user/bank_balance', name: 'UserBankAccount', meta: { title: '结算信息' }, component: () => import('@/views/user/bank_balance.vue') },
  { path: '/user/info', name: 'UserInfo', meta: { title: '我的资料' }, component: () => import('@/views/user/info.vue') },
  { path: '/user/auth', name: 'UserAuth', meta: { title: '实名认证' }, component: () => import('@/views/user/auth.vue') },
  { path: '/user/parent', name: 'UserParent', meta: { title: '推荐人绑定' }, component: () => import('@/views/user/parent.vue') },
  { path: '/user/set_info', name: 'setInfo', meta: { title: '修改门店信息' }, component: () => import('@/views/user/set_info.vue') },
  { path: '/user/withdraw', name: 'UserWithdraw', meta: { title: '提现' }, component: () => import('@/views/user/withdraw.vue') },
  { path: '/user/trans', name: 'UserTrans', meta: { title: '转账' }, component: () => import('@/views/user/trans.vue') },
  { path: '/user/quyu', name: 'quyu', meta: { noheader: true,title: '区域管理' }, component: () => import('@/views/user/quyu.vue') },
  { path: '/user/city', name: 'city', meta: { noheader: true,title: '市代管理' }, component: () => import('@/views/user/city.vue') },
  { path: '/user/city_my', name: 'city_my', meta: { noheader: true,title: '市代管理' }, component: () => import('@/views/user/city_my.vue') },
  { path: '/user/money', name: 'UserMoney', meta: {noheader: true, title: '余额' }, component: () => import('@/views/user/money.vue') },
  { path: '/user/xieyi', name: 'UserXieyi', meta: {title: '共享经济合作伙伴协议' }, component: () => import('@/views/user/xieyi.vue') },
  
  { path: '/shop', name: 'ShopList', meta: { title: '我的门店' }, component: () => import('@/views/shop/list.vue') },
  { path: '/shop/detail', name: 'ShopDetail', meta: { title: '门店详情' }, component: () => import('@/views/shop/detail.vue') },
  { path: '/shop/apply', name: 'ShopApply', meta: { title: '申请店铺' }, component: () => import('@/views/shop/apply.vue') },
  { path: '/shop/photo', name: 'ShopPhoto', meta: { title: '门店照片' }, component: () => import('@/views/shop/photo.vue') },
  { path: '/shop/address', name: 'ShopAddress', meta: { title: '收货地址' }, component: () => import('@/views/shop/address.vue') },
  { path: '/shop/addAddress', name: 'ShopAddAddress', meta: { title: '编辑收货地址' }, component: () => import('@/views/shop/addAddress.vue') },
  { path: '/shop/bill', name: 'ShopBill', meta: { title: '账单记录' }, component: () => import('@/views/shop/bill.vue') },

  { path: '/pay', name: 'PayPay', meta: { title: '上传付款凭证' }, component: () => import('@/views/pay/pay.vue') },
  { path: '/pay/payWay', name: 'payWay', meta: { title: '选择支付方式' }, component: () => import('@/views/pay/payWay.vue') },
  { path: '/pay/detail', name: 'PayDetail', meta: { title: '付款详情' }, component: () => import('@/views/pay/detail.vue') },

  { path: '/buy', name: 'BuyList', meta: { title: '购买商品' }, component: () => import('@/views/buy/list.vue') },
  { path: '/buy/buy', name: 'buyBuy', meta: { title: '购买商品' }, component: () => import('@/views/buy/buy.vue') },

  { path: '/order', name: 'OrderList', meta: { title: '订单' }, component: () => import('@/views/order/list.vue') },
  { path: '/order/detail', name: 'OrderDetail', meta: { title: '订单详情' }, component: () => import('@/views/order/detail.vue') },
  { path: '/order/logistics', name: 'OrderLogistics', meta: { title: '物流信息' }, component: () => import('@/views/order/logistics.vue') },

  { path: '/list/order', name: 'listorder', meta: { title: '订单' }, component: () => import('@/views/list/order.vue') },
  { path: '/list/vip', name: 'listvip', meta: { title: '会员列表' }, component: () => import('@/views/list/vip.vue') },
  { path: '/list/vipDetail', name: 'listvipdetail', meta: { title: '会员详情' }, component: () => import('@/views/list/vipDetail.vue') },
  { path: '/list/shop', name: 'listshop', meta: { title: '店铺' }, component: () => import('@/views/list/shop.vue') },
  { path: '/list/qu', name: 'listqu', meta: { title: '区域代理' }, component: () => import('@/views/list/qu.vue') },
  { path: '/list/shi', name: 'listshi', meta: { title: '市级代理' }, component: () => import('@/views/list/shi.vue') },
  { path: '/list/detail', name: 'listdetail', meta: { title: '订单详情' }, component: () => import('@/views/list/detail.vue') },
  { path: '/list/address', name: 'listaddress', meta: { title: '物流详情' }, component: () => import('@/views/list/address.vue') },
  { path: '/list/filtAgen', name: 'filtAgen', meta: { title: '代理查询' }, component: () => import('@/views/list/filtAgen.vue') },

  { path: '/city_list/order', name: 'city_listorder', meta: { title: '订单' }, component: () => import('@/views/city_list/order.vue') },
  { path: '/city_list/shop', name: 'city_listshop', meta: { title: '店铺' }, component: () => import('@/views/city_list/shop.vue') },
  { path: '/city_list/qu', name: 'city_listqu', meta: { title: '区域代理' }, component: () => import('@/views/city_list/qu.vue') },
  { path: '/city_list/shi', name: 'city_listshi', meta: { title: '市级代理' }, component: () => import('@/views/city_list/shi.vue') },
  { path: '/city_list/detail', name: 'city_listdetail', meta: { title: '订单详情' }, component: () => import('@/views/city_list/detail.vue') },
  { path: '/city_list/bind', name: 'city_listbind', meta: { title: '绑定业务员' }, component: () => import('@/views/city_list/bind.vue') },
  { path: '/city_list/filtAgen', name: 'city_listfiltAgen', meta: { title: '代理查询' }, component: () => import('@/views/city_list/filtAgen.vue') },
  { path: '/city_list/list', name: 'city_listList', meta: { title: '业务员管理' }, component: () => import('@/views/city_list/list.vue') },
  { path: '/city_list/vip', name: 'city_listVip', meta: { title: '会员列表' }, component: () => import('@/views/city_list/vip.vue') },

  { path: '/city_list_my/order', name: 'city_list_myorder', meta: { title: '订单' }, component: () => import('@/views/city_list_my/order.vue') },
  { path: '/city_list_my/shop', name: 'city_list_myshop', meta: { title: '店铺' }, component: () => import('@/views/city_list_my/shop.vue') },
  { path: '/city_list_my/qu', name: 'city_list_myqu', meta: { title: '区域代理' }, component: () => import('@/views/city_list_my/qu.vue') },
  { path: '/city_list_my/shi', name: 'city_list_myshi', meta: { title: '市级代理' }, component: () => import('@/views/city_list_my/shi.vue') },
  { path: '/city_list_my/detail', name: 'city_list_mydetail', meta: { title: '订单详情' }, component: () => import('@/views/city_list_my/detail.vue') },
  { path: '/city_list_my/bind', name: 'city_list_mybind', meta: { title: '绑定业务员' }, component: () => import('@/views/city_list_my/bind.vue') },
  { path: '/city_list_my/filtAgen', name: 'city_list_myfiltAgen', meta: { title: '代理查询' }, component: () => import('@/views/city_list_my/filtAgen.vue') },
  { path: '/city_list_my/list', name: 'city_list_myList', meta: { title: '业务员管理' }, component: () => import('@/views/city_list_my/list.vue') },
  { path: '/city_list_my/vip', name: 'city_list_myVip', meta: { title: '会员列表' }, component: () => import('@/views/city_list_my/vip.vue') },
  
  { path: '/viewStore', name: 'ViewStoreList', meta: { title: '门店查看' }, component: () => import('@/views/view_store/list.vue') },

  { path: '/recommend', name: 'RecommendList', meta: { title: '店铺会员' }, component: () => import('@/views/recommend/list.vue') },
  { path: '/member/money', name: 'member_money', meta: { title: '我的资产' }, component: () => import('@/views/member/money.vue') },
  { path: '/member/balance_log', name: 'member_balance_log', meta: { title: '账单记录' }, component: () => import('@/views/member/balance_log.vue') },
  { path: '/member/transfer', name: 'member_transfer', meta: { title: '资产转换' }, component: () => import('@/views/member/transfer.vue') },
  { path: '*', name: 'NotFound', meta: { noheader: true, title: '页面丢失' }, component: () => import('@/views/not_found/index.vue') },
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
