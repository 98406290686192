/******************************
 * 封装请求
******************************/
import axios from 'axios'
import Qs from 'qs'
import { Config } from '@/js/Config'
import { href } from './route'
import { setSession, getSession, removeSession } from './storage'
import { Toast } from "vant";
import GlobalData from "@/js/GlobalData";
import { wxLogin, isWeixin } from '@/js/api/other'
import md5 from "js-md5";
import ba from 'vue-ba'

// 封装请求
export const request: api.request['request'] = ({ loading = '数据加载中，请稍后 . . .', prefix_url, url, data = {}, method = 'POST', type = 'form', success, successError, fail }) => {
  let content_type: string = ''
  let new_data = data
  switch (type) {
    case 'form':
      content_type = 'application/x-www-form-urlencoded'
      new_data = Qs.stringify(data)
      break;
    case 'json':
      content_type = 'application/json'
      new_data = JSON.stringify(data)
      break;
    case 'formdata':
      content_type = 'multipart/form-data'
      break;
  }
  if (loading) {
    var toast = Toast.loading({
      message: loading,
      forbidClick: true,
      icon: require('@/assets/loading.gif'),
      className: 'request-loading'
    });
  }
  // 百度统计（请求的数据）
  ba.trackEvent({
    category: (prefix_url ? prefix_url : Config.AXIOS_HOST) + url,
    action: '数据请求',
  })
  axios({
    url: (prefix_url ? prefix_url : Config.AXIOS_HOST) + url,
    params: { 'access-token': getSession('token') },
    data: new_data, method,
    headers: {
      'Content-Type': content_type
    },
  }).then((res) => {
    
    if(loading){
      toast.clear();
    }

    if (res.data.code == "101") { // 基本信息未完善，跳转完善信息界面
      href('/login/basicInfo')
      Toast(res.data.message)
    // } else if (res.data.code === "102") { // 推荐人未填，跳转到填写推荐人页面
    //   href('/user/parent?user_id=' + (data.user_id || ''))
    //   Toast(res.data.message)
    } else if (res.data.code == "400") { // 错误
      if (fail) { fail(res) }
      Toast(res.data.message)
      console.log()
      if(window.location.href.indexOf('buy?user_id')!=-1&&res.data.message.indexOf('地址')!=-1){//报有关地址且在购买页
        // console.log(this)
        href('/shop/addAddress?user_id='+localStorage.getItem('nowUserId'))
      }
    } else if (res.data.code == 401) { // token失效
      Toast({
        message: "授权过期或失效",
        forbidClick: true,
        onClose: () => {
          if (isWeixin()) {
            removeSession('token');
            let key = md5(Math.random() + "" + new Date().getTime());
            let openid = getSession('openid');
            setSession("key_1", String(key)); // 存储本地的key
            wxLogin(key)
          } else {
            href('/')
          }
        }
      })
    } else if (res.data.code == 200) { // 成功
      if (success) { success(res.data) }
    } else {
      if(loading){
        toast.clear();
      }
      Toast.allowMultiple(true);
      Toast(res.data.message)
      errLog('log/error',{
        url,
        post:new_data,
        data:res.data,
        user:localStorage.getItem('nowUserId')
      })
      if (fail) { fail(res.data); }
    }
  }).catch((res) => {
    if(loading){
      toast.clear();
    }
    Toast('请求出错')
    // 加载中的loading框
    if (fail) { fail(res) 
      errLog('log/error',{
        url,
        post:new_data,
        data:res.data,
        user:localStorage.getItem('nowUserId')
        // localStorage.getItem('nowUserId')
      })
    }
  });
}

function errLog(url,data){
  request({
    url,
    data
  })
}

// h5的图片上传
export const uploadFile: api.request['uploadFile'] = (event, success, fail) => {
  let form = new FormData();
  form.append('file', event, event.name);
  request({
    url: 'common/file/images',
    type: 'formdata',
    loading: '图片上传中...',
    data: form,
    success(res) {
      if (success) { success(res); }
    },
    fail(res) {
      if (fail) { fail(res); }
    }
  })
}

// 获取全局状态，已请求过则直接读取
export const getStatusAll: api.request['getStatusAll'] = (callback) => {
  if (GlobalData.status_all) {
    callback(GlobalData.status_all);
  } else {
    request({
      url: "common/shop/status-all",
      success(res) {
        GlobalData.status_all = res.data;
        callback(res.data);
      }
    });
  }
}

// 发送验证码
export const sendCode: api.request['sendCode'] = (mobile, callback) => {
  request({
    url: "common/sms/register",
    data: { mobile },
    success(res) {
      Toast('验证成功，验证码已发送');
      if (callback) { callback() }
    },
    fail(res) {
      Toast(res.message);
    }
  });
}