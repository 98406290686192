/******************************
 * 链接跳转
******************************/
import router from '@/router';
import route from '@/router/index'
import Event from "@/core/Event";
// 链接跳转
export const href: api.route['href'] = (path, data = {}): void => {
  route.push({ path, params: data });
}
// 链接跳转（url带参数）
export const link: api.route['link'] = (path, data = {}) => {
  route.push({ path, query: data });
}
// 设置标题
export const setTitle: api.route['setTitle'] = (title) => {
  Event.$emit('setTitle', title)
}
// 设置标题
export const goBack: api.route['goBack'] = () => {
  router.go(-1);
}
