/******************************
 * MyForm表单的封装方法
******************************/
// 获取form表单的数据
export const getFormData: api.myForm['getFormData'] = (form_lists) => {
  let data: Dictionary<string> = {};
  form_lists.forEach((list, i) => {
    list.forEach(item => {
      if (!item.hidden) { 
        data[item.name] = item.value;
      }
    });
  })
  return data;
}
// 重新加载表单（刷新表单）
export const refreshForm: api.myForm['refreshForm'] = (refs) => {
  refs.forEach(item => {
    if (item && item.onload) {
      item.onload();
    }
  })
}
// 公众号的登录（跳转到后台获取token）
export const wxLogin: api.other['wxLogin'] = (key) => {
  window.location.href =
    "https://html5.miyinxinliansuo.com/login/wechat-login?key=" +
    key +
    "&oauth_callback=" +
    encodeURIComponent(
      window.location.protocol + "//" + window.location.host + "/#"
    );
}