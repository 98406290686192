import Vue from 'vue';
import App from './App.vue';
import router from '@/router';

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  /* 需要注册百度地图开发者来获取你的ak */
  ak: 'OF2Aki5GkhG1CNrfyWh3j3QNvgAxOWh3'
})

import ba from 'vue-ba'
Vue.use(ba, {
  siteId: 'a670e384350d2d81787b7ba09866037a',
  src: "https://hm.baidu.com/hm.js?a670e384350d2d81787b7ba09866037a"
})
// 百度统计（页面跳转时，统计每个页面的访问数）
router.beforeEach((to, from, next) => {
  if (to.path) {
    ba.trackPageview('/#' + to.path);
  }
  next();
})

import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import '@/fonts/iconfont.css';
import '@/fonts_lz/iconfont.css';


import '@/css/main.css';

import api from './js/api'
Vue.prototype.$api = api

// 全局变量（不用vuex）
import GlobalData from "@/js/GlobalData";
Vue.prototype.$globalData = GlobalData

Vue.config.productionTip = false;
new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
