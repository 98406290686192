/******************************
 * 其他方法
******************************/
// 获取url
export const getUrlParam: api.other['getUrlParam'] = (name) => {
  let href = window.location.href
  let url = "?" + href.split("?")[1]
  let theRequest = new Object();
  if (typeof href === "undefined") {
    url = decodeURI(location.search); // 获取url中"?"符后的字符串
  }
  if (url.indexOf("?") !== -1) {
    let str = url.substr(1);
    let strs = str.split("&");
    for (let item of strs) {
      theRequest[item.split("=")[0]] = decodeURI(item.split("=")[1]);
    }
  }
  if (theRequest[name]) { return theRequest[name]; }
  return null;
}
// 判断是否是微信浏览器
export const isWeixin: api.other['isWeixin'] = () => {
  let ua = navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i)) {
    return true;
  } else {
    return false;
  }
}
// 公众号的登录（跳转到后台获取token）
export const wxLogin: api.other['wxLogin'] = (key) => {
  window.location.href =
    "https://html5.miyinxinliansuo.com/login/wechat-login?key=" +
    key +
    "&oauth_callback=" +
    encodeURIComponent(
      window.location.protocol + "//" + window.location.host + "/#"
    );
}